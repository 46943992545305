import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEventType,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';

export class LoggingInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) {
          if(typeof event.body != 'string' && event.url.includes("adminpanallogin")){
              sessionStorage.setItem('token',event.body.token)
              sessionStorage.setItem('_id',event.body.user._id)
              sessionStorage.setItem('email',event.body.user.email)
          }
        }
      }),
    );
  }
}

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import {NbAuthService, NbAuthSimpleToken} from '@nebular/auth';
import {Router} from '@angular/router';
import 'rxjs/add/operator/do';
import {NbToastrService, NbGlobalPhysicalPosition} from '@nebular/theme';
import {Injectable} from '@angular/core';
import {Setting} from './setting';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private authService: NbAuthService, private router: Router, private toastrService: NbToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const baseUrl = Setting.baseUrl + 'api/v1/';

    const changeUrl = req.clone({url:baseUrl,method:'post'});

    const config = {
      destroyByClick: true,
      duration: 3000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true,
    };
    if(req.url != undefined && req.url.includes("admin_panalchangepassword")){
      req.body['uid'] = sessionStorage.getItem('_id');
    }

    return next.handle(req).do((event: HttpEvent<any>) => {
      var body = event['body']
      var url = event['url']

      if ((event instanceof HttpResponse && event.status) === 201 || (body != undefined && url !=  undefined && typeof body != 'string' && body.StatusCode == 400 && url.includes("adminpanallogin"))) {
        this.toastrService.warning('something went wrong',
          config);
          return false
      }
      if (body != undefined && url !=  undefined && typeof body != 'string' && body.StatusCode == 402 && url.includes("admin_panalchangepassword")) {
        this.toastrService.warning('something went wrong',
          config);
          return false
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err) {
          this.toastrService.warning('something went wrong',
            config);
        } else if (err.status === 500) {
          this.toastrService.warning(
            'something went wrong',
            config);
        } else if (err.status === 501) {
          this.toastrService.warning(
            'something went wrong',
            config);
        }
      }
    });
  }

  private addJsonHeader(request: HttpRequest<any>): HttpRequest<any> {

    if (request.headers.has('Content-Type')) {
      return;
    }

    return request.clone({
      headers: request.headers.append('Content-Type', 'application/json'),
    });
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.authService.isAuthenticated()) {
      return request;
    }

    let userToken: string = '';

    this.authService.onTokenChange()
      .subscribe((token: NbAuthSimpleToken) => {
        if (token.isValid()) {
          userToken = token.getValue();
        }
      });

    return request.clone({
      headers: request.headers.append('Authorization', `Bearer ${userToken}`),
    });
  }
}
